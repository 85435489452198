import React from 'react';
import styled from 'styled-components';

const LegalLine = styled.div`
  margin-top: 40px;
  p {
    font-size: 1.2rem;
  }
  a {
    color: var(--light_grey);
    font-weight: bold;
    font-family: var(--Obviously);
    font-size: 1.2rem;
  }
  a:first-of-type {
    margin-right: 20px;
  }
`;

export default function LegalDisclaimer() {
  return (
    <LegalLine>
      <p>
        <a href="https://aeriz.com">aeriz.com</a>
        <a href="https://aeriz.com/privacy">Privacy</a>
      </p>
    </LegalLine>
  );
}
