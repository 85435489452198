/* eslint react/destructuring-assignment: 'off' */
/* eslint react/no-access-state-in-setstate: 'off' */
/* eslint react/no-unsafe: 'off' */
/* eslint no-unused-vars: 'off' */

import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';
import weightedRandom from '../utils/weightedRandom';
import PreferenceSelect from './PreferenceSelect';
import extractEmails from '../utils/extractEmails';

const FormWrapper = styled.div`
  input::placeholder {
    color: var(--light_grey);
    font-size: 1.6rem;
  }

  input[type='email'] {
    background-color: transparent;
    background: transparent;
    border-radius: 50px;
    border: 2px solid var(--light_grey);
    padding: 0 20px;
    font-family: 'Montserrat';
    font-size: 1.6rem;
    width: 340px;
    max-width: 50vw;
    height: 53px;
    /* margin-right: 40px; */
  }

  input[type='email']:focus,
  input[type='email']:focus-within {
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    background-color: white !important;
    -webkit-text-fill-color: black;
    /* box-shadow: 0 0 0px 1000px var(--_93Yellow) inset; */
    /* -webkit-box-shadow: 0 0 0px 1000px var(--_93Yellow) inset; */
    transition: background-color 5000s ease-in-out 0s;
  }

  h1 {
    margin: 80px auto 60px;
    line-height: 1;
    font-weight: 900;
    color: var(--charcoal);
  }

  p {
    font-size: 1.6rem;
    color: var(--light_grey);
    max-width: 50ch;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 20px;
  }

  p.error-desc {
    color: var(--charcoal);
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0;
  }

  input[type='submit'] {
    border-radius: 55px;
    border: 2px solid var(--aeriz_blue);
    background-color: var(--aeriz_blue);
    font-family: 'Montserrat';
    height: 53px;
    font-size: 1.6rem;
    font-weight: 800;
    letter-spacing: var(--letter_spacing);
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    padding: 10px 40px;
    margin: 40px 0 40px 16px;
  }

  input[type='submit']:hover {
    color: var(--aeriz_blue);
    background-color: white;
    transition: 0.3s ease-in-out all;
  }

  @media only screen and (max-width: 530px) {
    input[type='submit'] {
      margin: 40px;
    }
    h1 {
      margin: 40px 0 30px;
    }
  }
`;

export default class WaldoEmailCollectionForm extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      result: null,
      productPref: '',
      items: ['pair of socks', 'tote bag', 'dad hat', 'lighter'],
      weights: [2, 1, 0.5, 1],
      prize: '',
    };
    this.wrapperRef = React.createRef();
    this.emailRef = React.createRef();
  }

  // UNSAFE_componentWillMount() {
  //   this.setState({
  //     prize: weightedRandom(this.state.items, this.state.weights).item,
  //   });
  // }

  _handleSubmit = async (e) => {
    e.preventDefault();
    const emailRef = this.emailRef.current;
    const wrapper = this.wrapperRef.current;
    // If Pref select or email is blank, check them individually
    if (this.state.productPref === '' || emailRef.value === '') {
      // If Pref Select is blank, shake Pref Select
      if (this.state.productPref === '') {
        if (wrapper.classList.contains('no-pref-selected')) {
          wrapper.classList.remove('no-pref-selected');
          wrapper.classList.add('no-pref-selected-still');
        } else if (wrapper.classList.contains('no-pref-selected-still')) {
          wrapper.classList.remove('no-pref-selected-still');
          wrapper.classList.add('no-pref-selected');
        } else {
          wrapper.classList.add('no-pref-selected');
        }
      }
      // If email is blank, shake email
      if (emailRef.value === '') {
        if (wrapper.classList.contains('no-email')) {
          wrapper.classList.remove('no-email');
          wrapper.classList.add('no-email-still');
        } else if (wrapper.classList.contains('no-email-still')) {
          wrapper.classList.remove('no-email-still');
          wrapper.classList.add('no-email');
        } else {
          wrapper.classList.add('no-email');
        }
      }
      // If both are complete, move on and subit the form data
    } else if (navigator.onLine === true) {
      wrapper.classList.add('success');
      const result = await addToMailchimp(
        this.state.email,
        {
          SOURCE: 'Waldo Fest Signup',
          PROD_PREF: this.state.productPref,
          // PRIZE: this.state.prize,
        },
        'https://aeriz.us16.list-manage.com/subscribe/post?u=91696e16d33357ab3af649f12&amp;id=acea049382'
      );

      this.setState({
        result,
      });

      emailRef.blur();

      if (localStorage.getItem('entries') != null) {
        // * Get localstorage Item
        // * Parse to JSON
        // * Append / Push new object data
        // * Stringify object
        // * Set Local storage to new stringified object

        const initialLocalStorageEntries = localStorage.getItem('entries');
        const parsedInitialEntries = JSON.parse(initialLocalStorageEntries);
        parsedInitialEntries.push({
          id: this.state.email + this.state.productPref,
          email: this.state.email,
          productPreference: this.state.productPref,
          prize: this.state.prize,
        });
        const stringifiedUpdatedEntries = JSON.stringify(parsedInitialEntries);
        localStorage.setItem('entries', stringifiedUpdatedEntries);
        console.log(parsedInitialEntries);
      } else {
        // * Create Data object
        // * Stringify it
        // * Set Local storage "Entries"
        const entriesObject = [
          {
            id: this.state.email + this.state.productPref,
            email: this.state.email,
            productPreference: this.state.productPref,
            prize: this.state.prize,
          },
        ];
        const stringifiedEntries = JSON.stringify(entriesObject);
        localStorage.setItem('entries', stringifiedEntries);
      }

      // Reload page after X seconds
      setTimeout(() => {
        window.location.reload(false);
      }, 60000);
    } else if (localStorage.getItem('entries') != null) {
      // * Get localstorage Item
      // * Parse to JSON
      // * Append / Push new object data
      // * Stringify object
      // * Set Local storage to new stringified object

      const initialLocalStorageEntries = localStorage.getItem('entries');
      const parsedInitialEntries = JSON.parse(initialLocalStorageEntries);
      parsedInitialEntries.push({
        id: this.state.email + this.state.productPref,
        email: this.state.email,
        productPreference: this.state.productPref,
        prize: this.state.prize,
      });
      const stringifiedUpdatedEntries = JSON.stringify(parsedInitialEntries);
      localStorage.setItem('entries', stringifiedUpdatedEntries);
      console.log(parsedInitialEntries);
    } else {
      // * Create Data object
      // * Stringify it
      // * Set Local storage "Entries"
      const entriesObject = [
        {
          id: this.state.email + this.state.productPref,
          email: this.state.email,
          productPreference: this.state.productPref,
          prize: this.state.prize,
        },
      ];
      const stringifiedEntries = JSON.stringify(entriesObject);
      localStorage.setItem('entries', stringifiedEntries);
    }
  };

  handleChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleSelectUpdate = (e) => {
    this.setState({
      productPref: e.target.value,
      prize: weightedRandom(this.state.items, this.state.weights).item,
    });
    console.log(this.state.prize);
    // console.log(e.target.value);
  };

  render() {
    let heading;
    let message;
    let formVisibility;
    let ErrorMessage;
    let entryPrize;
    // Set up Prize entry name based on product pref state
    if (this.state.productPref === 'Flower') {
      entryPrize = `Volcano Vaporizer`;
    } else if (this.state.productPref === 'Concentrates') {
      entryPrize = 'Puffco Peak';
    }

    if (this.state.result === null) {
      heading = 'Either way, you win.';
      message = (
        <p>
          Sign up and take home free merch from our table- everyone gets a
          prize! Plus, you'll be entered in a raffle to win either a Volcano
          Vaporizer or Puffco Peak- just let us know if you're more of a flower
          lover or a concentrate aficionado below:
        </p>
      );
    } else if (this.state.result.result === 'success') {
      formVisibility = { display: 'none' };
      heading = `You're entered to win a ${entryPrize}!`;
      message = (
        <p>
          Plus you’re getting a{' '}
          <span style={{ fontWeight: 'bold', color: 'var(--charcoal)' }}>
            free {this.state.prize}
          </span>{' '}
          right now! Talk to an Aeriz team member to claim your prize. Raffle
          winners will be notified via email.
        </p>
      );
    } else if (this.state.result.result === 'error') {
      heading = 'Uh oh — there was an error!';
      message = (
        <p>
          It looks like there was an issue with your email. Please make sure
          everything is spelled correctly and try again.
        </p>
      );
      // ErrorMessage = this.state.result.msg.substring(
      //   0,
      //   this.state.result.msg.indexOf('<')
      // );

      const signupEmail = extractEmails(this.state.result.msg);
      if (signupEmail != null) {
        ErrorMessage = `Looks like ${signupEmail} is already on the list- right near the top.`;
      } else {
        ErrorMessage = this.state.result.msg;
      }
    } else {
      heading = 'Uh oh — there was an error!';
      message = (
        <p>
          It looks like there was an issue with your email. Please make sure
          everything is spelled correctly and try again.
        </p>
      );
      ErrorMessage = this.state.result.msg;
    }

    return (
      <FormWrapper ref={this.wrapperRef}>
        <h1>{heading}</h1>
        {message}
        <form
          onSubmit={this._handleSubmit}
          style={formVisibility}
          autoComplete="off"
        >
          <PreferenceSelect
            className="pref-select-wrapper"
            productPref={this.state.productPref}
            handleSelectUpdate={this.handleSelectUpdate}
          />
          <input
            ref={this.emailRef}
            id="aeriz-email"
            label="Email"
            type="email"
            inputMode="email"
            name="email"
            autoComplete="off"
            placeholder="Email Address"
            onChange={this.handleChange}
          />
          <input type="submit" value="Submit" />
        </form>
        <p className="error-desc">{ErrorMessage}</p>
      </FormWrapper>
    );
  }
}
