import React from 'react';
import styled from 'styled-components';
import Logo from '../components/Logo.js';
import WaldoEmailCollectionForm from '../components/WaldoEmailCollectionForm';
import Stamp from '../components/Stamp';
import LegalDisclaimer from '../components/LegalDisclaimer';

const PageWrapper = styled.section`
  /* width: 100vw; */
  min-height: 100vh;
  display: grid;
  place-content: center;
  text-align: center;
  padding: 0 24px;
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  /* background-color: blue; */
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
  justify-content: center;
  display: grid;

  @media (max-width: 800px) {
    margin-top: 30px;
  }
`;

export default function HomePage() {
  return (
    <PageWrapper className="aeriz-email-capture">
      <ContentWrapper className="waldos-content-wrapper">
        <Logo className="aeriz-logo-main" />
        <WaldoEmailCollectionForm />
        <a href="/waldos-fest">
          <Stamp />
        </a>
        <LegalDisclaimer />
      </ContentWrapper>
    </PageWrapper>
  );
}
