/* eslint react/destructuring-assignment: 'off' */
/* eslint react/no-access-state-in-setstate: 'off' */
/* eslint no-unused-vars: 'off' */

import React from 'react';
import styled from 'styled-components';

const PreferenceHeading = styled.h4`
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--charcoal);
`;

const RadioWrapper = styled.div`
  .radio-element {
    position: relative;
    cursor: pointer;
    display: inline-block;
  }

  .radio-element label {
    display: flex;
    margin: 4px 32px 0;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--charcoal);
  }

  .radio-element input[type='radio'] {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 60px;
    height: 30px;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid var(--charcoal);
  }

  .radio-element input:checked ~ .checkmark {
    background-color: var(--charcoal);
  }
`;

export default class PreferenceSelect extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectUpdate = props.handleSelectUpdate;
    this.productPref = props.productPref;
    this.state = {};
  }

  render() {
    return (
      <>
        {/* <PreferenceHeading>I Prefer:</PreferenceHeading> */}
        <RadioWrapper>
          <div className="radio-element">
            <label htmlFor="radio-flower">
              <input
                type="radio"
                id="radio-flower"
                name="preference-select"
                value="Flower"
                onChange={this.handleSelectUpdate}
              />
              <span className="checkmark" />
              Flower
            </label>
          </div>
          <div className="radio-element">
            <label htmlFor="radio-concentrate">
              <input
                type="radio"
                id="radio-concentrate"
                name="preference-select"
                value="Concentrates"
                onChange={this.handleSelectUpdate}
              />
              <span className="checkmark" />
              Concentrates
            </label>
          </div>
        </RadioWrapper>
      </>
    );
  }
}
